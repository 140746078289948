///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import GlobalProvider from 'src/shared/contexts/global';
import Login from './modules/Login';
import ResetPassword from './modules/ResetPassword';
import Register from './modules/Register';
import Confirm from './modules/Confirm';
import ResetPasswordProceed from './modules/ResetPasswordProceed';

export default function App() {
  return (
    <>
      <GlobalStyle />
      <GlobalProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset" component={ResetPassword} />
          <Route exact path="/reset/proceed" component={ResetPasswordProceed} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/register/confirm" component={Confirm} />
        </Switch>
      </GlobalProvider>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  html {
    font-family: sofia-pro, sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 14px;
  }
  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;
  }
`;
