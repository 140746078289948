///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, GreyButton } from 'src/app/components/Button';
import { Title, Input, Actions, Field, Option, FieldLabel, InlineError } from 'src/auth/components';
import Dropdown from 'src/auth/components/Dropdown';

export const ERROR_INVALID_FIRSTNAME = 'Please enter your first name';
export const ERROR_INVALID_LASTNAME = 'Please enter your last name';
export const ERROR_INVALID_COMPANY = 'Please enter your company name';
export const ERROR_INVALID_REFERER = 'Please enter where you heard about IOWA from';

const INTERESTS = [
  ['interest_new_data', 'Looking for New Content & Data Sources'],
  ['interest_data_capture', 'Help with Data Capture/Harvest Technology'],
  ['interest_data_distribution', 'Help with Content Distribution Technology'],
  ['interest_data_commercial', 'Help with a Data Commercialisation Strategy including Legals'],
  ['interest_marketing', 'Help with Digital Marketing including Design & Video'],
  ['interest_other', 'Other'],
];

const REFERERS = ['LinkedIn','Press', 'Search Engine', 'Partner', 'Referral', 'Other'];

export default function Contact({ fields, setField, onNext, onBack }) {
  const [errors, setErrors] = React.useState({});

  const onSubmit = e => {
    e.preventDefault();
    const errors = {};

    if(!fields.firstname) {
      errors.firstname = ERROR_INVALID_FIRSTNAME;
    }

    if(!fields.lastname) {
      errors.lastname = ERROR_INVALID_LASTNAME;
    }

    if(!fields.company) {
      errors.company = ERROR_INVALID_COMPANY;
    }

    if(!fields.referral) {
      errors.referral = ERROR_INVALID_REFERER;
    }

    if(Object.keys(errors).length > 0) {
      setErrors(errors);

    } else {
      onNext();
    }
  }

  return (
    <form action="" method="POST" onSubmit={onSubmit}>
      <Title>Contact Information</Title>
      <Row>
        <Input type="text" testid="input:firstname" error={errors.firstname} placeholder="First Name*" value={fields.firstname || ''} onChange={e => setField('firstname', e.target.value)} />
        <Input type="text" testid="input:lastname" error={errors.lastname} placeholder="Last Name*" value={fields.lastname || ''} onChange={e => setField('lastname', e.target.value)} />
      </Row>
      <Input type="text" testid="input:company" error={errors.company} placeholder="Company Name*" value={fields.company || ''} onChange={e => setField('company', e.target.value)} />
      <Input type="text" testid="input:job_title" placeholder="Job Title" value={fields.job_title || ''} onChange={e => setField('job_title', e.target.value)} />
      <Input type="text" testid="input:phone" placeholder="Phone Number" value={fields.phone || ''} onChange={e => setField('phone', e.target.value)} />
      <Input type="text" testid="input:country" placeholder="Country" value={fields.country || ''} onChange={e => setField('country', e.target.value)} />
        <Dropdown options={REFERERS} testid="input:referer" error={errors.referral} placeholder="How did you hear about IOWA? *" id={r => r} label={r => r} value={fields.referral} onChange={value => setField('referral', value)} />
      <Field>
        <FieldLabel>I am also interested in:</FieldLabel>
        {INTERESTS.map(([key, label]) => (
          <Option
            key={key}
            checked={Boolean(fields[key])}
            onChange={() => setField(key, !fields[key])}
            testid={`input:${key}`}
          >{label}</Option>
        ))}
      </Field>
      <Actions>
        <GreyButton data-testid="action:back" onClick={onBack}>Back</GreyButton>
        <PrimaryButton data-testid="action:next" type="submit">Next</PrimaryButton>
      </Actions>
    </form>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    flex-grow: 1;
    margin-left: 10px;
  }
  > :first-child {
    margin-left: 0;
  }
`;