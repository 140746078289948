///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { PrimaryButton } from 'src/app/components/Button';
import { Field, Input, BackLink, Actions } from 'src/auth/components';
import isValidEmail from 'src/shared/lib/isValidEmail';

export default function Username({ onError, onSuccess }) {
  const [username, setUsername] = React.useState('');

  const onSubmitUsername = async (username) => {
    if(!isValidEmail(username)) {
      onError('Please enter a valid email address');
      return;
    }

    try {
      const response = await axios.post('/reset', { username });

      if(!response?.data?.res) {
        throw new Error(response?.data?.err || 'Something went wrong, please try again');
      }

      onSuccess(username);

    } catch(err) {
      onError(err.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitUsername(username);
  }
  
  return (
    <form action="" method="POST" onSubmit={handleSubmit}>
      <Fieldset>
        <Field>
          <Input type="text" name="username" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
        </Field>
      </Fieldset>
      <Actions>
        <BackLink to="/login">Back to Login</BackLink>
        <PrimaryButton type="submit">Next</PrimaryButton>
      </Actions>
    </form>
  );
}

const Fieldset = styled.div`
  margin-bottom: 30px;
`;
