///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Footer } from 'src/app/components/Layout';

export default function Container({ children }) {
  return (
    <>
      <GlobalStyle />
      <Background>
        <Contents>
          {children}
        </Contents>
        <Footer />
      </Background>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  html {
    font-family: sofia-pro, sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 14px;
  }
  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }
`;

const Background = styled.div`
  background: url(/bg4.png) #f3f3f3;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Contents = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
