///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import isoContext from 'src/auth/contexts/iso';
import { PrimaryButton, GreyButton } from 'src/app/components/Button';
import { Title, Actions, Option } from 'src/auth/components';
import { ErrorMessage, InlineError } from '../../components/Elements';

export default function Terms({ fields, setField, setFields, onNext, onBack }) {
  const [disabled, setDisabled] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const { recaptchaKey } = React.useContext(isoContext);

  const changeField = (key, value) => {
    setField(key, value);
    delete errors[key];
    setErrors({ ...errors });
  }

  const register = async () => {
    setDisabled(true);
    try {
      const response = await axios.post('/register', fields);
      if(!response?.data?.res) {
        throw new Error(response?.data?.err || 'Something went wrong, please try again');
      }
    } catch(err) {
      throw new Error('Something went wrong, please try again');
    }
  }

  const onSubmit = e => {
    e.preventDefault();
    const errors = {};

    if(!fields.privacy_policy_accepted) {
      errors.privacy_policy_accepted = 'You must accept our Terms and Privacy Policy in order to create an account';
    }

    if(!fields.retokHmac) {
      errors.captcha = 'Please complete the CAPTCHA';
    }

    if(Object.keys(errors).length > 0) {
      setErrors(errors);

    } else {
      register()
      .then(() => {
        setDisabled(false);
        onNext();
      })
      .catch(err => {
        setDisabled(false);
        setError(err.message);
      });
    }
  }

  const onCaptcha = async token => {
    delete errors['captcha'];
    setErrors({ ...errors });
    const response = await axios.post('/recaptcha', { retok: token, username: fields.username });
    if(response.data?.res && response.data.hmac) {
      setFields({ ...fields, retok: token, retokHmac: response.data.hmac });

    } else {
      setErrors({ ...errors, captcha: 'Something went wrong, please try again' });
    }
  }

  return (
    <form action="" method="POST" onSubmit={onSubmit}>
      <Title>Terms & Privacy</Title>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <p>Disclaimer:</p>
      <p>MDX Technology Limited is committed to protecting and respecting your privacy,
        and we’ll only use your personal information to administer your account and to provide the
        products and services you requested from us. From time to time, we would like to contact
        you about our products and services, as well as other content that may be of interest to
        you. If you consent to us contacting you for this purpose, please tick below to say how
        you would like us to contact you:</p>
        <Option
          checked={Boolean(fields.marketing_opt_in)}
          onChange={() => changeField('marketing_opt_in', !fields.marketing_opt_in)}
          testid="input:marketing_opt_in"
        >I agree to receive other communications from MDX Technology Limited</Option>
        <p>You can unsubscribe from these communications at any time. For more information on how
          to unsubscribe, our privacy practices, and how we are committed to protecting and
          respecting your privacy, please review our <Link href="/privacy-policy">Privacy Policy</Link>.</p>

        <p>In order to provide you the content requested, we need to store and process your personal
          data. If you consent to us storing your personal data for this purpose, please tick the
          checkbox below.</p>
        <Option
          checked={Boolean(fields.privacy_policy_accepted)}
          onChange={() => changeField('privacy_policy_accepted', !fields.privacy_policy_accepted)}
          error={errors.privacy_policy_accepted}
          testid="input:privacy_policy_accepted"
        >
          I agree to MDX Technology's <Link href="/terms">Terms of Use</Link> and <Link href="/privacy-policy">Privacy Policy</Link>.
        </Option>
        <CAPTCHA>
          <ReCAPTCHA sitekey={recaptchaKey} onChange={onCaptcha} />
          {errors.captcha && <InlineError>{errors.captcha}</InlineError>}
        </CAPTCHA>
      <Actions>
        <GreyButton data-testid="action:back" onClick={onBack}>Back</GreyButton>
        <PrimaryButton disabled={disabled} data-testid="action:next" type="submit">Next</PrimaryButton>
      </Actions>
    </form>
  );
}

const Link = styled.a`
  color: #000;
  font-weight: bold;
`;

const CAPTCHA = styled.div`
  margin-top: 20px;
`;