///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';

export default function Step({ active, children, ...attrs }) {
  return (
    <Element active={active} data-active={active} {...attrs}>{children}</Element>
  );
}

const Element = styled.div`
  opacity: 0.5;
  padding: 8px 20px;
  ${({ active }) => active && `
    opacity: 1;
    font-weight: bold;
    background: rgba(255, 255, 255, 1);
  `}
`;
