///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { GreyButton } from 'src/app/components/Button';
import { Actions } from 'src/auth/components';

export default function Submitted({ onBack }) {
  return (
    <div>
      <Message>
      If you have an existing MDX Marketplace account, you have been emailed a link.
      Please click this link to reset your password.
      </Message>
      <Actions>
        <GreyButton onClick={onBack}>Back</GreyButton>
      </Actions>
    </div>
  );
}

const Message = styled.p`
  margin: 0 0 15px;
`;
