///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import isoContext from 'src/auth/contexts/iso';
import { PrimaryButton } from 'src/app/components/Button';
import { Field, Input, Actions, Title, ErrorMessage } from 'src/auth/components';
import Container from './Container';

export default function ResetPasswordProceed() {
  const { error: initialError } = React.useContext(isoContext);
  const [error, setError] = React.useState(initialError);
  const [password, setPassword] = React.useState('');

  return (
    <Container>
      <Column>
        <Title>Reset Your Password</Title>
        {error && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
        <form action="" method="POST">
          <Message>
            Please enter your desired new password:
          </Message>
          <Fieldset>
            <Field>
              <Input type="password" name="password" placeholder="New Password" value={password} onChange={e => setPassword(e.target.value)} />
            </Field>
          </Fieldset>
          <Actions>
            <PrimaryButton type="submit">Set Password</PrimaryButton>
          </Actions>
        </form>
      </Column>
    </Container>
  );
}

const Column = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  flex-shrink: 0;
  background: white;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.25);
`;

const Message = styled.p`
  margin: 0 0 15px;
`;

const Fieldset = styled.div`
  margin-bottom: 30px;
`;
