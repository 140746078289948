///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { InlineError } from './Elements';

export default function Option({ checked, children, onChange, error, testid }) {
  return (
    <Container data-testid={testid}>
      <Inline>
        <Checkbox
          type="checkbox"
          data-testid={`${testid}-checkbox`}
          checked={checked}
          onChange={onChange}
        />
        <Label>{children}</Label>
      </Inline>
      {error && <InlineError data-testid={`${testid}-error`}>{error}</InlineError>}
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 5px;
`;

const Inline = styled.label`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Checkbox = styled.input`
  display: inline-flex;
  margin: 0 8px 0 0;
  height: 19px;
`;

const Label = styled.div`
  opacity: 0.75;
  line-height: 19px;
`;
