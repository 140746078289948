///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';
import { Field, InlineError } from './Elements';

export default function Input({
  error,
  note,
  testid,
  ...attrs
}) {
  return (
    <Field>
      <Element
        data-testid={testid}
        error={Boolean(error)}
        data-error={error}
        {...attrs}
      />
      {note && !error && <Note data-testid={`${testid}-note`}>{note}</Note>}
      {error && <InlineError data-testid={`${testid}-error`}>{error}</InlineError>}
    </Field>
  );
}

const Element = styled.input`
  box-sizing: border-box;
  height: 36px;
  display: block;
  font-size: 14px;
  font-weight: 300;
  font-family: inherit;
  padding: 8px 10px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #999;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  ${({ error }) => error && `
    border-color: ${theme.danger};
  `}
`;

const Note = styled.p`
  margin: 5px 0 0;
  font-size: 12px;
  opacity: 0.5;
`;
