///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import isoContext from 'src/auth/contexts/iso';
import { Title, ErrorMessage } from 'src/auth/components';
import Container from '../Container';
import Username from './Username';
import Submitted from './Submitted';

export default function ResetPassword() {
  const { error: initialError } = React.useContext(isoContext);
  const [state, setState] = React.useState({ type: 'username' });
  const [error, setError] = React.useState(initialError);
  
  const nextStep = username => {
    setState({ type: 'token', username });
    setError(null);
  };

  return (
    <Container>
      <Column>
        <Title>Reset Your Password</Title>
        {error && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
        {(() => {
          switch(state.type) {
            case 'username': {

              return <Username onError={setError} onSuccess={nextStep} />;
            };
            case 'token': {
              const { username } = state;

              const onBack = () => setState({ type: 'username' });
              return <Submitted onBack={onBack} />;
            };
          };
        })()}
      </Column>
    </Container>
  );
}

const Column = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  flex-shrink: 0;
  background: white;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.25);
`;
