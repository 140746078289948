///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import * as theme from 'src/shared/theme';
import { PrimaryButton } from 'src/app/components/Button';
import isoContext from 'src/auth/contexts/iso';
import { Title, ErrorMessage, Field, Input } from 'src/auth/components';
import Container from './Container';

export default function Login() {
  const { error } = React.useContext(isoContext);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  return (
    <Container>
      <Columns>
        <Column>
          <Title>Login to Continue</Title>
          {error && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
          <form action="" method="POST">
            <Field>
              <Input type="text" name="username" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
            </Field>
            <Field>
              <Input type="password" name="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
            </Field>
            <Forgot><Link to="/reset" color={theme.brand_primary_border}>Forgot your password?</Link></Forgot>
            <PrimaryButton type="submit">Login</PrimaryButton>
          </form>
        </Column>
        <AsideColumn>
            <Logo src="/img/logo-wb.png" alt="MDX Marketplace" />
            <Signup>
              <Text>New to the MDX Marketplace?</Text>
              <Link to="/register" color="#000">Sign up for an account</Link>
            </Signup>
        </AsideColumn>
      </Columns>
    </Container>
  );
}

const Columns = styled.div`
  background: white;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  ${theme.above_mobile} {
    flex-direction: row;
  }
`;

const Column = styled.div`
  padding: 40px;
  width: 350px;
  ${theme.above_mobile} {
    width: 250px;
  }
`;

const AsideColumn = styled(Column)`
  background: url(/bg3.png) ${theme.brand_primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Forgot = styled.p`
  margin: 0 0 15px;
`;

const Logo = styled.img`
  max-width: 100%;
  margin: 0 0 40px;
  width: 250px;
  height: 101px;
`;

const Link = styled(NavLink)`
  text-decoration: none;
  ${({ color }) => `
    color: ${color};
    border-bottom: 1px solid ${color};
  `}
  font-weight: bold;
  display: inline-flex;
  padding: 2px 0;
`;

const Signup = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Text = styled.p`
  margin: 0 0 10px;
`;