///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';
import Container from '../Container';
import analyticsContext from 'src/shared/contexts/analytics';
import { ErrorMessage } from 'src/auth/components';
import Account from './Account';
import Contact from './Contact';
import Terms from './Terms';
import Step from './Step';
import Success from './Success';

export default function Register({ error }) {
  const [step, setStep] = React.useState(0);
  const { trackEvent } = React.useContext(analyticsContext);
  const [fields, setFields] = React.useState({
    marketing_opt_in: false,
    privacy_policy_accepted: false,
    password: '',
    username: '',
    firstname: '',
    lastname: '',
    company: '',
    job_title: '',
    phone: '',
    country: '',
    referral: '',
    is_creator: false,
    is_consumer: false,
    interest_new_data: false,
    interest_data_capture: false,
    interest_data_distribution: false,
    interest_data_commercial: false,
    interest_marketing: false,
    interest_other: false,
  });

  const setField = (key, value) => setFields({ ...fields, [key]: value });

  const prevStep = () => {
    setStep(step - 1);
  }

  const nextStep = () => {
    setStep(step + 1);
  }

  React.useEffect(() => {
    trackEvent('registration_step', { event_category: 'registration', step: step + 1, of: 5 });
  }, [step]);

  return (
    <Container>
      <Columns>
        <Column width={step === 2 ? '450px' : '350px'}>
          {error && (
            <ErrorMessage data-testid="error">{error}</ErrorMessage>
          )}
          {(() => {
            switch(step) {
              case 0:
                return (
                  <Account
                    fields={fields}
                    setField={setField}
                    onNext={nextStep}
                  />
                );
              case 1:
                return (
                  <Contact
                    fields={fields}
                    setField={setField}
                    onNext={nextStep}
                    onBack={prevStep}
                  />
                );
              case 2:
                return (
                  <Terms
                    fields={fields}
                    setField={setField}
                    setFields={setFields}
                    onNext={nextStep}
                    onBack={prevStep}
                  />
                );
              case 3:
                return (
                  <Success />
                );
            }
          })()}
        </Column>
        {step < 4 && (
          <Steps>
            <Step data-testid="step:account" active={step === 0}>1. Account Details</Step>
            <Step data-testid="step:contact" active={step === 1}>2. Contact Information</Step>
            <Step data-testid="step:terms" active={step === 2}>3. Terms & Privacy</Step>
            <Step data-testid="step:confirm" active={step === 3}>4. Confirm Email</Step>
          </Steps>
        )}
      </Columns>
    </Container>
  );
}

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  padding: 8px;
  ${theme.above_mobile} {
    width: 200px;
  }
`;

const Columns = styled.div`
  background: white;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  ${theme.above_mobile} {
    flex-direction: row;
  }
`;

const Column = styled.div`
  padding: 20px 40px 40px;
  width: ${({ width = '250px' }) => width};
`;