///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';


export default function BackLink({ to, children }) {
  return (
    <Container to={to}>
      <Icon><i className="fa fa-chevron-left" /></Icon>
      <span>{children}</span>
    </Container>
  )
}

const Container = styled(NavLink)`
  text-decoration: none;
  color: #666;
  display: inline-flex;
  align-items: center;
  &:hover > span {
    text-decoration: underline;
  }
`;

const Icon = styled.i`
  font-size: 12px;
  display: inline-flex;
  margin-right: 6px;
`;