///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';

export default function useEventListener(target, event, handler, watch = []) {
  React.useEffect(() => {
    target.addEventListener(event, handler);
    return () => target.removeEventListener(event, handler);
  }, [target, event, handler, ...watch]);
}