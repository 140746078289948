///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';

const context = React.createContext();

export default context;

export function ServerProvider({ error, recaptchaKey, analyticsId, children }) {
  const setError = () => {};

  return (
    <>
      <script dangerouslySetInnerHTML={{ __html: `
        window.ISO_CONTEXT = ${JSON.stringify({ error, recaptchaKey, analyticsId })};`}}></script>
      <context.Provider value={{ error, recaptchaKey, setError }}>
        {children}
      </context.Provider>
    </>
  );
}

export function ClientProvider({ children }) {
  const { error: initialError, recaptchaKey } = window.ISO_CONTEXT;
  const [error, setError] = React.useState(initialError || null);
  return (
    <>
      <script dangerouslySetInnerHTML={{ __html: `
        window.ISO_CONTEXT = ${JSON.stringify(window.ISO_CONTEXT)};`}}></script>
      <context.Provider value={{ error, recaptchaKey, setError }}>
        {children}
      </context.Provider>
    </>
  );
}