///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { PrimaryButton } from 'src/app/components/Button';
import { Title, Field, Input, Actions, BackLink, InlineError, FieldLabel, Option } from 'src/auth/components';
import isValidEmail from 'src/shared/lib/isValidEmail';

export const ERROR_INVALID_EMAIL = 'Email address must be valid';
export const ERROR_INVALID_PASSWORD = 'Password must be at least 12 letters';
export const ERROR_INVALID_CONFIRM_PASSWORD = 'Passwords need to match';
export const ERROR_INVALID_ACCOUNT_TYPE = 'Please choose an account type';

const ACCOUNT_TYPES = [
  ['is_consumer', 'Consumer'],
  ['is_creator', 'Provider'],
];

export default function Account({ fields, setField, onNext }) {
  const [errors, setErrors] = React.useState({});

  const changeField = (key, value) => {
    setField(key, value);
    delete errors[key];
    setErrors({ ...errors });
  }

  const onSubmit = e => {
    e.preventDefault();
    const errors = {};

    if(!isValidEmail(fields.username)) {
      errors.username = ERROR_INVALID_EMAIL;
    }

    if(!fields.password || fields.password.length < 12) {
      errors.password = ERROR_INVALID_PASSWORD;

    } else if(fields.password !== fields.confirm_password) {
      errors.confirm_password = ERROR_INVALID_CONFIRM_PASSWORD;
    }

    if(!fields.is_consumer && !fields.is_creator) {
      errors.account_type = ERROR_INVALID_ACCOUNT_TYPE;
    }

    if(Object.keys(errors).length > 0) {
      setErrors(errors);

    } else {
      onNext(); 
    }
  }

  return (
    <form action="" method="POST" onSubmit={onSubmit}>
      <Title data-testid="title">Account Details</Title>
      <Input
        type="text"
        placeholder="Business Email *"
        testid="input:username"
        error={errors.username}
        value={fields.username || ''}
        onChange={e => changeField('username', e.target.value)}
      />
      <Input
        type="password"
        placeholder="Password *"
        testid="input:password"
        error={errors.password}
        value={fields.password || ''}
        onChange={e => changeField('password', e.target.value)}
      />
      <Input
        type="password"
        placeholder="Confirm Password *"
        testid="input:confirm_password"
        error={errors.confirm_password}
        value={fields.confirm_password || ''}
        onChange={e => changeField('confirm_password', e.target.value)}
      />
      <Field>
        <FieldLabel>I am a market data:</FieldLabel>
        {ACCOUNT_TYPES.map(([key, label]) => (
          <Option
            key={key}
            checked={Boolean(fields[key])}
            onChange={() => changeField(key, !fields[key])}
            testid={`input:${key}`}
          >{label}</Option>
        ))}
        {errors.account_type && <InlineError data-testid="error:account_type">{errors.account_type}</InlineError>}
      </Field>
      <Actions>
        <BackLink data-testid="action:back" to="/login">Back to Login</BackLink>
        <PrimaryButton type="submit" data-testid="action:next">Next</PrimaryButton>
      </Actions>
    </form>
  );
}
