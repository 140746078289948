///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';
import useEventListener from 'src/shared/lib/useEventListener';
import { Field, InlineError } from './Elements';

export default function Dropdown({ options, value, onChange, id, label, placeholder, error, testid }) {
  const ref = React.useRef();
  const [open, setOpen] = React.useState(false);
  const selectedOption = options.find(option => id(option) == value);

  useEventListener(document.body, 'click', event => {
    if(open && ref.current && !(event.target && ref.current.contains(event.target))) {
      setOpen(false);
    }
  }, [open, ref.current]);

  const valueLabel = (() => {
    if(selectedOption) {
      return label(selectedOption);
    }
    
    return <Placeholder>{placeholder || '-- Please Select --'}</Placeholder>;
  })();

  const selectOption = (optionId) => {
    onChange(optionId);
    setOpen(false);
  }

  return (
    <Field>
      <Wrapper open={open} ref={ref} data-testid={testid} onClick={e => e.stopPropagation()}>
        <Trigger onClick={() => setOpen(!open)} error={Boolean(error)}>
          <Value>{valueLabel}</Value>
          <Icon><i className="fa fa-chevron-down" /></Icon>
        </Trigger>
        <Options>
        {options.map((option) => (
          <Option key={id(option)} selected={id(option) == value} onMouseDown={() => selectOption(id(option))}>
            <Label>{label(option)}</Label>
          </Option>
        ))}
        </Options>
      </Wrapper>
      {error && <InlineError data-testid={`${testid}-error`}>{error}</InlineError>}
    </Field>
  )
}

const Wrapper = styled.div`
  position: relative;
  min-width: 150px;
  ${({ open }) => open && `
    > ${Options} {
      display: block;
    }
  `}
`;

const Trigger = styled.div`
  box-sizing: border-box;
  height: 36px;
  width: 100%;
  border: none;
  padding: 8px 10px;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  border: 1px solid #999;
  background: white;
  ${({ error }) => error && `
    border-color: ${theme.danger};
  `}
`;

const Placeholder = styled.span`
  opacity: 0.5;
`;

const Value = styled.div`
  flex-grow: 1;
  align-items: center;
`;

const Icon = styled.div`
  opacity: 0.35;
  margin-left: 5px;
`;

const Options = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: -1px;
  background: white;
  display: none;
  min-width: 100%;
  z-index: 1;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  border: 1px solid #999;
  max-height: ${35 * 5}px;
  overflow-y: auto;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 10px;
  border-top: 1px solid #eee;
  cursor: pointer;
  white-space: nowrap;
  ${({ selected }) => selected && `
    background: ${theme.highlight};
  `}
  &:first-child {
    border-top: none;
  }
  &:hover {
    background: #eee;
  }
`;

const Label = styled.div`
  flex-grow: 1;
`;

const Alt = styled.div`
  opacity: 0.5;
  padding-left: 10px;
`;