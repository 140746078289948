///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from 'styled-components';
import * as theme from 'src/shared/theme';
import analyticsContext from 'src/shared/contexts/analytics';
import { PrimaryButton } from 'src/app/components/Button';
import isoContext from 'src/auth/contexts/iso';
import { ErrorMessage, Title, Actions } from 'src/auth/components';
import Container from './Container';

export default function Confirm() {
  const { error } = React.useContext(isoContext);
  const { trackEvent } = React.useContext(analyticsContext);

  React.useEffect(() => {
    trackEvent('registration_step', { event_category: 'registration', step: 5, of: 5, error });
  }, [error]);

  const goToCatalogue = () => {
    window.location.href = '/catalogue';
  };

  if(error) {

  }

  return (
    <Container>
      <Columns>
        <Column>
          {error ? (
            <ErrorMessage data-testid="error">{error}</ErrorMessage>
          ) : (
            <>
              <Title data-testid="title">Email Verified</Title>
              <p>Your email address has been verified successfully and you have been logged in.</p>
              <Actions>
                <PrimaryButton data-testid="action:catalogue" onClick={goToCatalogue}>Continue to Catalogue</PrimaryButton>
              </Actions>
            </>
          )}
        </Column>
      </Columns>
    </Container>
  );
}

const Columns = styled.div`
  background: white;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  ${theme.above_mobile} {
    flex-direction: row;
  }
`;

const Column = styled.div`
  padding: 40px;
  width: 350px;
`;
