///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { PrimaryButton } from 'src/app/components/Button';
import { Title, Actions } from 'src/auth/components';

export default function Token() {
  return (
    <>
      <Title data-testid="title">Verify Your Email Address</Title>
      <p>We've sent you an email containing a verification link. Please click this link to verify your email address.</p>
      <p>If you don't see it in your inbox, be sure to check your junk email folder.</p>
      <Actions>
        <PrimaryButton as="a" href="/catalogue" data-testid="action:return">Return to Catalogue</PrimaryButton>
      </Actions>
    </>
  );
}
