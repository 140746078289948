///////////////////////////////////////////////////////////////////////////////
//
//  IOWArocks Server
//  (C) Copyright 2020 MDX Technology Ltd
//
///////////////////////////////////////////////////////////////////////////////

import styled from 'styled-components';
import * as theme from 'src/shared/theme';

export const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  margin: 0 0 20px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;

export const ErrorMessage = styled.p`
  background: #ffdddd;
  margin: 0 0 15px;
  padding: 8px 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Field = styled.label`
  display: block;
  margin-bottom: 15px;
`;

export const InlineError = styled.p`
  margin: 5px 0 0;
  color: ${theme.danger};
  font-size: 12px;
`;

export const FieldLabel = styled.div`
  flex-grow: 1;
  margin: 0 0 10px;
  padding-top: 10px;
`;